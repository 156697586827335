import { useApi } from '../useApi'
import { Repo, RepositoryManipulationService } from '../../api/coreapi'
import { getIsUserSideFailure } from '../../utils/errorClassify'

export const useRepo = (repoId?: string) => {
  const { data, loading, error, refresh } = useApi<Repo>(
    ['repos', repoId],
    repoId ? () => RepositoryManipulationService.srcHandlersv2RepoGet({ repoId }) : null,
    true
  )
  return { repo: data, repoLoading: loading, failed: error && !getIsUserSideFailure(error).isUserSideFailure, refresh }
}
