import React from 'react'
import styled from '@emotion/styled'

const StyledImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
`

export const InviteImage = () => {
  return <StyledImage src={require('../../assets/images/sloths-invite.png')} alt="Invite image" />
}
