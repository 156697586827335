import styled from '@emotion/styled'

const StyledImage = styled.img`
  height: auto;
  display: block;
  max-height: 200px;
  width: 100%;
  object-fit: contain;
`

export const CollaborationImage = () => {
  return <StyledImage src={require('../../assets/images/sloths-collaborating.png')} alt="Collaboration image" />
}
