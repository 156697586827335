import { Organization, OrganizationManagementService } from '../../api/coreapi'
import { useItemListApi } from '../useApi'

export function useOrganizations() {
  const { data, error } = useItemListApi<Organization>(
    ['organizations'],
    async () => await OrganizationManagementService.srcHandlersv2OrganizationListAll({})
  )

  return {
    organizations: data ?? [],
    error,
  }
}
