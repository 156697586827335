import styled from '@emotion/styled'
import { FormControl, TextField, Typography, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { infoToast, errorToast } from '../../utils/toast'
import { FlexColumn } from '../base/Flex'
import { ActionDialog } from './ActionDialog'
import { InviteImage } from './InviteImage'
import { OrganizationManagementService } from '../../api/coreapi/services/OrganizationManagementService'
import { AccessMode } from '../../api/coreapi'
import { callAsync } from '../../utils/callAsync'

const StyledForm = styled(FlexColumn)`
  width: 100%;
`

const Description = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.black.secondary};
  margin-bottom: 1.5rem;
  line-height: 1.5;
`

const RoleGroup = styled(FormControl)`
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-radius: 4px;
  padding: 1rem;
  gap: 0.5rem;
`

const RoleLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.colors.black.primary};
  font-weight: 500;
`

const RoleOption = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-weight: 500;
  }
`

const RoleDescription = styled(Typography)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black.secondary};
  margin-left: 2rem;
`

const FormSection = styled(FlexColumn)`
  gap: 0.75rem;
`

const ItalicBold = styled.span`
  font-style: italic;
  font-weight: 500;
`

const FieldContainer = styled.div`
  /* Adjust the min-height to ensure it reserves space for potential error messages */
  min-height: 74px;
`

// RFC 5322 compliant email regex
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  orgId: string
  orgName: string
  invitedEmail: string
  setInvitedEmail: (email: string) => void
  afterInvite?: () => void
}

export const OrganizationInviteDialog = ({
  isOpen,
  setOpen,
  orgId,
  orgName,
  invitedEmail,
  setInvitedEmail,
  afterInvite,
}: Props) => {
  const [emailError, setEmailError] = useState<string>('')
  const [role, setRole] = useState(AccessMode.READ)
  const [isLoadingInviteMember, setIsLoadingInviteMember] = useState(false)
  const postAnalytics = useAnalytics()
  const onApiError = (error: Error) => {
    if (error.message.includes('already a member')) {
      errorToast(`${invitedEmail} is already a member of ${orgName}`)
    } else {
      errorToast('Failed to invite member')
    }

    setInvitedEmail('')
    setEmailError('')
    setOpen(false)
  }

  const validateEmail = (email: string): boolean => {
    if (!EMAIL_REGEX.test(email)) {
      setEmailError('Please enter a valid email address')
      return false
    }
    setEmailError('')
    return true
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value
    setInvitedEmail(newEmail)
    if (newEmail) {
      validateEmail(newEmail)
    } else {
      setEmailError('')
    }
  }

  const handleInvite = async () => {
    if (!validateEmail(invitedEmail)) {
      return
    }
    await callAsync(
      async () => {
        await OrganizationManagementService.srcHandlersv2OrganizationInviteMember({
          orgId,
          requestBody: {
            email: invitedEmail,
            role,
          },
        })
        infoToast(`Invitation sent to ${invitedEmail}`, true)
        afterInvite?.()
      },
      setIsLoadingInviteMember,
      onApiError,
      () => setOpen(false)
    )
    postAnalytics('OrganizationInviteDialogConfirmed', {
      org_id: orgId,
      invitee_email: invitedEmail,
      role,
    })
  }

  useEffect(() => {
    if (isOpen) {
      postAnalytics('OrganizationInviteDialogOpened', { org_id: orgId })
    }
    setEmailError('')
    setRole(AccessMode.READ)
  }, [isOpen, orgId, postAnalytics, setInvitedEmail])

  return (
    <ActionDialog
      title={`Invite member`}
      isOpen={isOpen}
      setOpen={setOpen}
      onConfirmAsync={handleInvite}
      message={
        <FlexColumn gap={1} centered>
          <InviteImage />
          <Description>
            Invite someone to join <ItalicBold>{orgName}</ItalicBold> and collaborate on repositories.
          </Description>
          <StyledForm gap={2}>
            <FormSection>
              <FieldContainer>
                <TextField
                  label="Email address"
                  type="email"
                  value={invitedEmail}
                  onChange={handleEmailChange}
                  onBlur={() => invitedEmail && validateEmail(invitedEmail)}
                  error={!!emailError}
                  helperText={emailError}
                  fullWidth
                  required
                />
              </FieldContainer>
            </FormSection>
            <FormSection>
              <RoleGroup>
                <RoleLabel>Role</RoleLabel>
                <RadioGroup value={role} onChange={(e) => setRole(e.target.value as AccessMode)}>
                  <div>
                    <RoleOption value={AccessMode.ADMIN} control={<Radio size="small" />} label="Admin" />
                    <RoleDescription>Can manage organization settings, members, and all repositories.</RoleDescription>
                  </div>
                  <div>
                    <RoleOption value={AccessMode.READ} control={<Radio size="small" />} label="Member" />
                    <RoleDescription>
                      Can view and collaborate on repositories they are given access to.
                    </RoleDescription>
                  </div>
                </RadioGroup>
              </RoleGroup>
            </FormSection>
          </StyledForm>
        </FlexColumn>
      }
      confirmButtonLabel="Send invitation"
      disabled={!invitedEmail || !!emailError || isLoadingInviteMember}
      loadingMessage="Sending invitation..."
      scrollable
    />
  )
}
