import { Organization } from '../../api/coreapi'
import { Button } from '@mui/material'
import { OptionsDropdown } from '../dropdown/OptionsDropdown'
import { KeyboardArrowDown } from '@mui/icons-material'

interface Props {
  organizations: Organization[]
  currentOrg?: Organization
  onOrgSelect: (org: Organization) => void
}

export const OrgSelector = ({ organizations, currentOrg, onOrgSelect }: Props) => {
  if (organizations.length <= 1) return null

  return (
    <OptionsDropdown
      button={
        <Button variant="outlined" endIcon={<KeyboardArrowDown />}>
          Select Organization
        </Button>
      }
      items={organizations.map((org) => ({
        key: org.id,
        title: org.name,
        onSelected: () => onOrgSelect(org),
        selected: org.id === currentOrg?.id,
      }))}
      arrow={false}
    />
  )
}
