import { useItemListApi } from '../useApi'
import { Repo, OrganizationManagementService } from '../../api/coreapi'
import { useMemo } from 'react'
import { sortBy } from 'lodash'

export const useOrganizationMemberRepos = (orgId: string, userId: string) => {
  const {
    data: repos,
    loading,
    refresh,
  } = useItemListApi<Repo>(['organizations', orgId, 'members', userId, 'repos'], () =>
    OrganizationManagementService.srcHandlersv2OrganizationListMemberRepos({ orgId, userId })
  )
  const sortedRepos = useMemo(() => (repos ? sortBy(repos, (repo) => repo.repo_name.toLowerCase()) : repos), [repos])
  return { repos: sortedRepos, loading, refresh }
}
