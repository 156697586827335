import React, { ChangeEvent, useMemo, useState } from 'react'
import { Button, IconButton, TableCell, TableRow, TextField } from '@mui/material'
import { TableCellWithLoading } from './TableCellWithLoading'
import { LoadingButton } from '@mui/lab'
import { Edit } from '@mui/icons-material'
import styled from '@emotion/styled'
import { TextRegular } from '../base/TextStyle'

const TableCellLabel = styled(TableCell)`
  ${TextRegular};
  color: ${({ theme }) => theme.colors.black.secondary};
  width: 25%;
  border: none;
`

const TableCellEdit = styled(TableCell)`
  color: ${({ theme }) => theme.colors.black.primary};
  border: none;
  white-space: nowrap;
  width: 1px;
  cursor: pointer;
`

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.black.secondary};
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.padding.s}rem;
`

type Props = {
  label: string
  value: string
  shouldShowEditButton: boolean
  isLoadingData: boolean
  isLoadingEdit?: boolean
  onSaveClicked?: () => Promise<void>
  setValue?: (value: string) => void
  editedValue?: string
  validateInput?: (input: string) => string
}

export const Row = ({
  label,
  value,
  shouldShowEditButton,
  isLoadingData,
  isLoadingEdit,
  onSaveClicked,
  setValue,
  editedValue,
  validateInput,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [error, setError] = useState('')

  const handleValidation = (input: string) => {
    const validationError = validateInput ? validateInput(input) : ''
    setError(validationError)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue!(event.target.value)
    handleValidation(event.target.value)
  }

  const onEditClicked = () => {
    // Set text field value to the original value
    if (setValue) {
      setValue!(value)
    }
    handleValidation(value)
    setIsEditing(true)
  }

  const onSaveClickedWrapper = async () => {
    if (onSaveClicked) {
      await onSaveClicked()
    }
    setIsEditing(false)
  }

  const isLoading = useMemo(() => isLoadingData || isLoadingEdit, [isLoadingData, isLoadingEdit])
  const key = useMemo(() => label.toLowerCase().replace(/\s+/g, '-'), [label])

  return (
    <TableRow key={key}>
      <TableCellLabel component="th" scope="row">
        {label}
      </TableCellLabel>
      <TableCellWithLoading
        loading={isLoadingData}
        value={
          isEditing ? (
            <TextField
              id={`${key}-text-field`}
              variant="standard"
              value={editedValue}
              onChange={handleChange}
              disabled={isLoading}
              error={!!error}
              helperText={error}
              autoFocus
              fullWidth
            />
          ) : (
            value
          )
        }
      />
      {shouldShowEditButton && (
        <TableCellEdit>
          {isEditing ? (
            <ButtonsWrapper>
              <LoadingButton
                color="primary"
                onClick={onSaveClickedWrapper}
                loading={isLoading}
                disabled={!!error}
                variant="outlined"
              >
                Save
              </LoadingButton>
              <CancelButton onClick={() => setIsEditing(false)} disabled={isLoading} variant="outlined">
                Cancel
              </CancelButton>
            </ButtonsWrapper>
          ) : (
            <IconButton color="primary" aria-label="Edit full name" onClick={onEditClicked}>
              <Edit />
            </IconButton>
          )}
        </TableCellEdit>
      )}
    </TableRow>
  )
}
