import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { pageQueryParam } from '../../Routes'
import { log } from '../../utils/log'
import isNil from 'lodash/isNil'
import { useSearchParams } from 'react-router-dom'
import { FlexColumnStyle } from '../base/Flex'
import { UserAvatar } from '../base/UserAvatar'
import { TextBig } from '../base/TextStyle'
import { Table, TableContainer } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import { useUpdateUserInfo } from '../../hooks/api/useUpdateUserInfo'
import { useUserInfo } from '../../hooks/api/useUserInfo'
import { Row } from './Row'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.black.primary};
  ${FlexColumnStyle};
  flex-grow: 1;
  padding: ${({ theme }) => theme.padding.xl}rem;
  align-content: center;
  justify-content: start;
  gap: 1.5rem;
`

const AvatarWrapper = styled.div`
  ${FlexColumnStyle};
  align-items: center;
  width: fit-content;
`

const SectionHeader = styled.div`
  ${TextBig};
  background-color: ${({ theme }) => theme.colors.white.secondary};
  border-radius: 0.5rem;
  padding: 8px 16px;
  margin-bottom: ${({ theme }) => theme.padding.s}rem;
`

const UserNameWrapper = styled.div`
  ${TextBig};
  padding: ${({ theme }) => theme.padding.l}rem 0;
`

export const UserProfileRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: userData, loading: isCurrentUserLoading, refresh: refreshUserData } = useUserInfo()
  const [fullName, setFullName] = useState(userData?.full_name || '')
  const { isUpdateUserInfoLoading, updateUserInfo } = useUpdateUserInfo({
    id: userData?.id || '',
    full_name: fullName,
  })

  const onSaveFullNameClicked = async () => {
    setFullName(fullName.trim())
    if (fullName !== userData?.full_name) {
      await updateUserInfo()
      await refreshUserData()
    }
  }

  const validateFullName = (name: string) => {
    const regex = /^[a-zA-Z0-9 _'-]{3,50}$/

    if (name.length < 3) {
      return 'Full name must be at least 3 characters.'
    }
    if (name.length > 50) {
      return 'Full name cannot exceed 50 characters.'
    }
    if (!regex.test(name)) {
      return 'Full name can only contain letters, numbers, spaces, underscores, hyphens, and apostrophes.'
    }
    return '' // No errors
  }

  useEffect(() => {
    const page = searchParams.get(pageQueryParam)
    log.info('ProfileRoute', { page })
    if (!isNil(page)) {
      setSearchParams({}, { replace: true })
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    if (userData) {
      setFullName(userData.full_name || '')
    }
  }, [userData])

  return (
    <Container>
      <AvatarWrapper>
        <UserAvatar
          picture={userData?.image}
          size="large"
          name={userData?.full_name}
          email={userData?.email ?? ''}
          hideTooltip
        />
        <UserNameWrapper>{userData?.full_name}</UserNameWrapper>
      </AvatarWrapper>
      <div>
        <SectionHeader>Personal</SectionHeader>
        <TableContainer>
          <Table sx={{ width: 'inherit' }} aria-label="User personal information table">
            <TableBody>
              <Row
                label="Full name"
                value={userData?.full_name || ''}
                editedValue={fullName || ''}
                setValue={setFullName}
                validateInput={validateFullName}
                shouldShowEditButton
                isLoadingData={isCurrentUserLoading}
                isLoadingEdit={isCurrentUserLoading || isUpdateUserInfoLoading}
                onSaveClicked={onSaveFullNameClicked}
              />
              <Row
                label="Email"
                value={userData?.email || ''}
                shouldShowEditButton={false}
                isLoadingData={isCurrentUserLoading}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  )
}
