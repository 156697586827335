import { useMemo } from 'react'
import { useApi } from '../useApi'
import { AccessMode, OrganizationManagementService, OrganizationMemberData } from '../../api/coreapi'

const isAdminPermissions = (organizationMemberData?: OrganizationMemberData) =>
  !!organizationMemberData && [AccessMode.ADMIN, AccessMode.OWNER].includes(organizationMemberData?.member.role)

export const useOrganizationMember = (orgId?: string, userId?: string) => {
  const { data, loading, error } = useApi<OrganizationMemberData>(
    ['organizations', orgId, 'members', userId],
    orgId && userId ? () => OrganizationManagementService.srcHandlersv2OrganizationGetMember({ orgId, userId }) : null,
    true
  )

  const isOrgAdmin = useMemo(() => isAdminPermissions(data), [data])

  return { organizationMemberData: data, loading, error, isOrgAdmin }
}
