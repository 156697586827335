/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error';
import type { Organization } from '../models/Organization';
import type { OrganizationInviteRequest } from '../models/OrganizationInviteRequest';
import type { OrganizationMemberData } from '../models/OrganizationMemberData';
import type { PaginatedOrganizationMemberList } from '../models/PaginatedOrganizationMemberList';
import type { Repo } from '../models/Repo';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrganizationManagementService {
    /**
     * List user's organizations
     * @returns any Success
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2OrganizationListAll({
        owned,
    }: {
        /**
         * Filter by organizations owned by the user or not.
         */
        owned?: boolean,
    }): CancelablePromise<{
        object: 'Organization';
        items: Array<Organization>;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations',
            query: {
                'owned': owned,
            },
        });
    }
    /**
     * List organization repositories
     * Get a list of all repositories in the organization
     * @returns any Success
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2OrganizationListRepos({
        orgId,
    }: {
        /**
         * An ID of an organization
         */
        orgId: string,
    }): CancelablePromise<{
        object: 'Repo';
        items: Array<Repo>;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{org_id}/repos',
            path: {
                'org_id': orgId,
            },
            errors: {
                403: `Unauthorized`,
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * List organization members
     * Get a paginated list of all members in the organization, sorted by name
     * @returns PaginatedOrganizationMemberList List of organization members
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2OrganizationListMembers({
        orgId,
        limit = 100,
        offset,
    }: {
        /**
         * Organization identifier
         */
        orgId: string,
        /**
         * Maximum number of items to return
         */
        limit?: number,
        /**
         * Number of items to skip
         */
        offset?: number,
    }): CancelablePromise<PaginatedOrganizationMemberList | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{org_id}/members',
            path: {
                'org_id': orgId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                403: `Unauthorized`,
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Get organization member details
     * Get details about a specific member in the organization
     * @returns OrganizationMemberData Organization member details
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2OrganizationGetMember({
        orgId,
        userId,
    }: {
        /**
         * An ID of an organization
         */
        orgId: string,
        /**
         * A user's ID
         */
        userId: string,
    }): CancelablePromise<OrganizationMemberData | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{org_id}/members/{user_id}',
            path: {
                'org_id': orgId,
                'user_id': userId,
            },
            errors: {
                403: `Unauthorized`,
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Delete a member from the organization
     * Only organization admins can delete members.
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2OrganizationDeleteMember({
        orgId,
        userId,
    }: {
        /**
         * An ID of an organization
         */
        orgId: string,
        /**
         * A user's ID
         */
        userId: string,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/organizations/{org_id}/members/{user_id}',
            path: {
                'org_id': orgId,
                'user_id': userId,
            },
            errors: {
                403: `Unauthorized`,
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * List repositories a member has access to within the organization
     * Returns a list of repositories the member will lose access to if removed.
     * @returns any Success
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2OrganizationListMemberRepos({
        orgId,
        userId,
    }: {
        /**
         * An ID of an organization
         */
        orgId: string,
        /**
         * A user's ID
         */
        userId: string,
    }): CancelablePromise<{
        object: 'Repo';
        items: Array<Repo>;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{org_id}/members/{user_id}/repos',
            path: {
                'org_id': orgId,
                'user_id': userId,
            },
            errors: {
                403: `Unauthorized`,
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Invite a new member to the organization
     * Sends an invitation to the specified user to join the organization  with the chosen role.
     *
     * @returns Error An error occurred
     * @returns any Invitation sent successfully.
     * @throws ApiError
     */
    public static srcHandlersv2OrganizationInviteMember({
        orgId,
        requestBody,
    }: {
        /**
         * An ID of an organization
         */
        orgId: string,
        requestBody: OrganizationInviteRequest,
    }): CancelablePromise<Error | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations/{org_id}/invites',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The request does not meet the required conditions`,
                401: `Unauthorized`,
                409: `User is already a member`,
            },
        });
    }
}
