import { FlexColumn, FlexRow } from '../../base/Flex'
import React, { JSX, ReactNode } from 'react'
import { TextRegular, TextSmall } from '../../base/TextStyle'
import styled from '@emotion/styled'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import { pluralize } from '../../../utils/textUtils'
import { Ellipsis } from '../../base/Ellipsis'
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import CloudIcon from '@mui/icons-material/Cloud'
import { keyframes } from '@emotion/react'
import SyncIcon from '@mui/icons-material/Sync'

const Title = styled.div`
  ${TextRegular};
  ${Ellipsis};
`
const SecondaryText = styled.div`
  ${TextSmall};
  ${Ellipsis};
`

const SyncStatusContainer = styled(FlexRow)`
  width: 100%;
  padding: 0.5rem;
`

const TextSection = styled(FlexColumn)`
  width: calc(100% - 2.5rem);
`

const IconWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledCloudIcon = styled(CloudIcon)`
  color: ${({ theme }) => theme.colors.header.primaryText};
  position: absolute;
`

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`

// Sync icon with spinning animation
const StyledSyncIcon = styled(SyncIcon)`
  color: ${({ theme }) => theme.colors.header.background};
  position: absolute;
  font-size: 12px !important;
  animation: ${spinAnimation} 1.5s linear infinite;
`

type SyncStatusProps = {
  icon: JSX.Element
  primaryText?: string
  secondaryText?: string
  onClick?: () => void
  className?: string
  tooltipText?: ReactNode
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
  },
})

export const SyncStatus = ({ icon, primaryText, secondaryText, onClick, className, tooltipText }: SyncStatusProps) => {
  return (
    <CustomWidthTooltip title={primaryText && tooltipText} arrow>
      <SyncStatusContainer
        className={className}
        gap={1}
        clickableCursor={onClick !== undefined}
        onClick={onClick}
        centered={true}
      >
        <Tooltip title={!primaryText && tooltipText} arrow>
          {icon}
        </Tooltip>
        <TextSection gap={0.3}>
          {primaryText && <Title>{primaryText}</Title>}
          {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
        </TextSection>
      </SyncStatusContainer>
    </CustomWidthTooltip>
  )
}

const BlackSyncStatus = styled(SyncStatus)`
  background-color: ${({ theme }) => theme.colors.header.background};
  color: ${({ theme }) => theme.colors.header.primaryText};
`

const StyledErrorSyncStatus = styled(SyncStatus)`
  background-color: ${({ theme }) => theme.colors.red.primary};
  color: ${({ theme }) => theme.colors.blue.contrastText};
`
export const WarningSyncStatus = styled(SyncStatus)`
  background-color: ${({ theme }) => theme.colors.warning.primary};
  color: ${({ theme }) => theme.colors.warning.contrastText};
`

// Helper to shorten the blob transfer status
const conciseStatus = (blobTransferStatus?: string) => {
  if (!blobTransferStatus) return blobTransferStatus

  const lines = blobTransferStatus
    .split('\n')
    .map((l) => l.trim())
    .filter(Boolean)

  // Helper to shorten long paths
  const shortenPath = (path: string) => {
    if (path.length <= 20) return path
    return path.slice(0, 5) + '...' + path.slice(-15)
  }

  // Process each line (shorten long paths)
  const processed = lines.map((line) => {
    const downloadPrefix = 'Downloading '
    const uploadPrefix = 'Uploading '

    let prefix: string | null = null
    if (line.startsWith(downloadPrefix)) {
      prefix = downloadPrefix
    } else if (line.startsWith(uploadPrefix)) {
      prefix = uploadPrefix
    }
    if (!prefix) return line

    const colonIndex = line.indexOf(':', prefix.length)
    if (colonIndex === -1) return line

    const path = line.slice(prefix.length, colonIndex)
    const rest = line.slice(colonIndex)
    return prefix + shortenPath(path) + rest
  })

  // Only show first few lines
  const limit = 5
  if (processed.length <= limit) {
    return processed.join('\n')
  }
  return processed.slice(0, limit).join('\n') + `\n... and ${processed.length - limit} more`
}

// split on newlines and return a JSX fragment
const MultilineTooltip = ({ title }: { title?: string }) => {
  if (!title) return null
  return (
    <>
      {title.split('\n').map((line, idx) => (
        <React.Fragment key={idx}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </>
  )
}

export const SyncingSyncStatus = ({
  numOfWaitingFiles,
  blobTransferStatus,
}: {
  numOfWaitingFiles?: number
  blobTransferStatus?: string
}) => {
  const tooltipContent = blobTransferStatus ? <MultilineTooltip title={conciseStatus(blobTransferStatus)} /> : undefined
  return (
    <BlackSyncStatus
      icon={
        <IconWrapper>
          <StyledCloudIcon />
          <StyledSyncIcon />
        </IconWrapper>
      }
      primaryText={'Syncing'}
      secondaryText={numOfWaitingFiles ? `${pluralize(numOfWaitingFiles, 'file')}...` : undefined}
      tooltipText={tooltipContent}
    />
  )
}

export const UpToDateSyncStatus = () => <BlackSyncStatus icon={<CloudDoneIcon />} tooltipText={'All changes synced'} />

export const ErrorSyncStatus = ({ error }: { error?: string }) => (
  <StyledErrorSyncStatus icon={<CloudOffIcon />} primaryText={'Sync error'} secondaryText={error} tooltipText={error} />
)

export const AgentDownStatus = ({ error }: { error?: string }) => (
  <StyledErrorSyncStatus
    icon={<CloudOffIcon />}
    primaryText={'Agent down'}
    secondaryText={error}
    tooltipText={'Go to the dashboard for more info. If the problem persists please contact support.'}
  />
)
