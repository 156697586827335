import React from 'react'
import { Box, Typography, Avatar } from '@mui/material'
import { styled } from '@mui/material/styles'

const NameCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

const UserInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}))

const UserName = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.colors.black.primary,
}))

const UserEmail = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.colors.black.secondary,
}))

interface MemberNameCellProps {
  image?: string
  fullName: string
  email: string
}

export const MemberNameCell: React.FC<MemberNameCellProps> = ({ image, fullName, email }) => {
  return (
    <NameCell>
      <Avatar src={image} alt={fullName} sx={{ width: 40, height: 40 }} />
      <UserInfo>
        <UserName variant="body1">{fullName || email}</UserName>
        {fullName && <UserEmail variant="body2">{email}</UserEmail>}
      </UserInfo>
    </NameCell>
  )
}
