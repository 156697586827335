/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrganization } from '../models/CreateOrganization';
import type { Error } from '../models/Error';
import type { NotificationList } from '../models/NotificationList';
import type { Organization } from '../models/Organization';
import type { Tier } from '../models/Tier';
import type { TierWrapper } from '../models/TierWrapper';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
    /**
     * Update current user's tier
     * @returns Tier Account tier updated
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2AccountUpdateTier({
        requestBody,
    }: {
        requestBody: TierWrapper,
    }): CancelablePromise<Tier | Error> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/tier',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create a new organization
     * Creates a new organization with the user as the owner If the user is already a member of an organization with the same name, the request will fail
     *
     * @returns Error An error occurred
     * @returns Organization Organization created
     * @throws ApiError
     */
    public static srcHandlersv2OrganizationPost({
        requestBody,
    }: {
        requestBody: CreateOrganization,
    }): CancelablePromise<Error | Organization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Organization with the same name already exists`,
            },
        });
    }
    /**
     * Get organization details
     * @returns Organization Organization details returned
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2OrganizationGetOrganization({
        orgId,
    }: {
        /**
         * An ID of an organization
         */
        orgId: string,
    }): CancelablePromise<Organization | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{org_id}',
            path: {
                'org_id': orgId,
            },
        });
    }
    /**
     * List all notifications for the user
     * @returns NotificationList Notifications returned
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2NotificationsListAll(): CancelablePromise<NotificationList | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notifications',
        });
    }
}
