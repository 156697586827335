import React, { ReactNode, useCallback, useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import { DangerButton, PrimaryButton, SecondaryButton } from '../base/PrimaryButton'
import styled from '@emotion/styled'
import { Loader } from '../base/Loader'
import { callAsync } from '../../utils/callAsync'
import { TextBig, TextTitle } from '../base/TextStyle'
import { PublishApiErrorContext } from '../../App'
import { useMountedState } from '../../hooks/useMountedState'
import { StyledDialogActions, StyledDialogContent } from './DialogStyle'
import { useFocusedButtons } from './useFocusedButtons'

const Message = styled.span`
  ${TextBig};
  width: 100%;
  color: ${({ theme }) => theme.colors.black.primary};
`

const Title = styled.span`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
`

type Props = {
  title: string
  message: ReactNode
  isOpen: boolean
  setOpen: (open: boolean) => void
  onConfirmAsync: () => Promise<void>
  confirmButtonLabel: string
  cancelButtonLabel?: string
  loadingMessage: string
  isDanger?: boolean
  noPrimaryAction?: boolean
  noSecondaryAction?: boolean
  disabled?: boolean
  maxHeight?: string
  scrollable?: boolean
}

export const ActionDialog = ({
  title,
  message,
  isOpen,
  setOpen,
  onConfirmAsync,
  confirmButtonLabel,
  cancelButtonLabel = 'Cancel',
  loadingMessage,
  isDanger,
  noPrimaryAction,
  noSecondaryAction,
  disabled,
  maxHeight,
  scrollable = false,
}: Props) => {
  const [loading, setLoading] = useMountedState(false)
  const onApiError = useContext(PublishApiErrorContext)
  const handleClose = useCallback(() => setOpen(false), [setOpen])
  const handleConfirm = useCallback(async () => {
    await callAsync(onConfirmAsync, setLoading, onApiError, handleClose)
  }, [onConfirmAsync, setLoading, onApiError, handleClose])
  const [primaryButtonRef, secondaryButtonRef] = useFocusedButtons(isOpen)

  return (
    <Dialog onClose={() => handleClose} aria-labelledby="customized-dialog-title" open={isOpen} disableEscapeKeyDown>
      <StyledDialogContent centerText={loading} maxHeight={maxHeight} scrollable={scrollable}>
        <Title>{title}</Title>
        {loading ? (
          <>
            <Loader />
            <Message>{loadingMessage}</Message>
          </>
        ) : (
          <Message>{message}</Message>
        )}
      </StyledDialogContent>
      {!loading && (
        <StyledDialogActions>
          {!noPrimaryAction &&
            (isDanger ? (
              <DangerButton ref={primaryButtonRef} disabled={disabled} onClick={() => handleConfirm()} tabIndex={0}>
                {confirmButtonLabel}
              </DangerButton>
            ) : (
              <PrimaryButton
                ref={primaryButtonRef}
                disabled={disabled || false}
                onClick={() => handleConfirm()}
                tabIndex={0}
              >
                {confirmButtonLabel}
              </PrimaryButton>
            ))}
          {!noSecondaryAction && (
            <SecondaryButton ref={secondaryButtonRef} onClick={handleClose} tabIndex={1}>
              {cancelButtonLabel}
            </SecondaryButton>
          )}
        </StyledDialogActions>
      )}
    </Dialog>
  )
}
