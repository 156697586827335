import { createContext } from 'react'

export enum SyncStatusType {
  None,
  Synced,
  Syncing,
  Error,
  Paused,
  NeedsUpdate,
  AgentDownError,
}

export type SyncStatusData = {
  syncStatus: SyncStatusType
  statusMsg?: string
  numOfFiles?: number
  blobTransferStatus?: string
}
type SyncStatusContextType = SyncStatusData & {
  doAction: ({ syncStatus, statusMsg, numOfFiles, blobTransferStatus }: SyncStatusData) => void
}

export const SyncStatusContext = createContext<SyncStatusContextType>({
  syncStatus: SyncStatusType.None,
  doAction: () => {},
})
