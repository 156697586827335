import { OrganizationManagementService, PaginatedOrganizationMemberList } from '../../api/coreapi'
import { useApiWithLoadingState } from '../useApi'

export const useMembers = (orgId?: string, page: number = 0) => {
  const { data, loading, error, refresh } = useApiWithLoadingState<PaginatedOrganizationMemberList>(
    ['organizations', orgId, 'members'],
    orgId
      ? () =>
          OrganizationManagementService.srcHandlersv2OrganizationListMembers({
            orgId,
            limit: 10,
            offset: page * 10,
          })
      : null
  )

  return {
    members: data?.items ?? [],
    total: data?.total ?? 0,
    hasMore: data?.has_more ?? false,
    loading,
    error,
    refresh,
  }
}
