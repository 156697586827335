import { styled } from '@mui/material/styles'
import { TableCell, Skeleton } from '@mui/material'

export const StyledSkeletonCell = styled(TableCell)<{ height?: string }>`
  height: ${({ height }) => height || '74px'};
  padding: 16px;
`

export const StyledSkeleton = styled(Skeleton)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
`
