import { FileEntry, RepositoryManipulationService } from '../../api/coreapi'
import { JournalOrdinalId } from '../../models/Tree'

type FilesPage = {
  items: FileEntry[]
  workspace_journal_ordinal_id: JournalOrdinalId
  finishedWithOrdinalError: boolean
}

const getEmptyPage = (): FilesPage => ({
  items: [],
  workspace_journal_ordinal_id: undefined,
  finishedWithOrdinalError: false,
})

const defaultItemsBatchSize = 8000

export const getTreePage = async (
  repoId: string,
  refId: string,
  includeDeleted: boolean,
  pathPrefix: string,
  workspaceJournalOrdinalId: JournalOrdinalId,
  dirsOnly: boolean | undefined,
  useSelectiveSync: boolean | undefined,
  onApiError: (error: any, key: string) => void,
  maxDepth?: number,
  batchSize: number = defaultItemsBatchSize
): Promise<FilesPage> => {
  try {
    return await treePageFetcher(
      repoId,
      refId,
      includeDeleted,
      pathPrefix,
      workspaceJournalOrdinalId,
      dirsOnly,
      useSelectiveSync,
      maxDepth,
      batchSize
    )
  } catch (e) {
    onApiError(e, ['repos', repoId, 'trees', refId, workspaceJournalOrdinalId, pathPrefix].toString())
    return getEmptyPage()
  }
}

const treePageFetcher = async (
  repoId: string,
  refId: string,
  includeDeleted: boolean,
  pathPrefix: string,
  workspaceJournalOrdinalId: number | undefined,
  dirsOnly: boolean | undefined,
  useSelectiveSync: boolean | undefined,
  maxDepth: number | undefined,
  batchSize: number
) => {
  const items: FileEntry[] = []
  let lastWorkspaceJournalOrdinalId = workspaceJournalOrdinalId
  let offSet: number | undefined = undefined
  let lastRes: FileEntry[] = []
  let finishedWithOrdinalError = false
  do {
    const filesPage = (await RepositoryManipulationService.srcHandlersv2TreeFiletree({
      repoId,
      refId,
      includeDeleted,
      path: pathPrefix,
      recurse: false,
      workspaceJournalOrdinalId: lastWorkspaceJournalOrdinalId,
      dirsOnly,
      useSelectiveSync,
      maxDepth,
      limit: batchSize,
      skip: offSet,
      ignoreJournalOrdinalDiff: true,
    })) as Omit<FilesPage, 'finishedWithOrdinalError'>
    if (lastWorkspaceJournalOrdinalId && lastWorkspaceJournalOrdinalId !== filesPage.workspace_journal_ordinal_id) {
      finishedWithOrdinalError = true
    }
    lastWorkspaceJournalOrdinalId = filesPage.workspace_journal_ordinal_id
    lastRes = filesPage.items
    items.push(...lastRes)
    offSet = (offSet || 0) + lastRes.length
  } while (lastRes.length === batchSize)
  return {
    items,
    workspace_journal_ordinal_id: lastWorkspaceJournalOrdinalId,
    finishedWithOrdinalError: finishedWithOrdinalError,
  }
}
