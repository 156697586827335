import styled from '@emotion/styled'
import { Button, CircularProgress, List, ListItem, Tooltip, Typography } from '@mui/material'
import { useState, useContext } from 'react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { PublishApiErrorContext } from '../../App'
import { FlexColumn, FlexRow } from '../base/Flex'
import { ActionDialog } from './ActionDialog'
import { CollaborationImage } from './CollaborateImage'
import { AccessMode, CollaboratorsService } from '../../api/coreapi'
import { StyledAccessModeDropdownForInvite } from '../dropdown/AccessModeDropdown'
import { callAsync } from '../../utils/callAsync'
import { Add } from '@mui/icons-material'
import { TextBold } from '../base/TextStyle'
import { useOrganizationRepos } from '../../hooks/api/useOrganizationRepos'

const StyledList = styled(List)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-radius: 4px;
  padding: 0;
  overflow-y: auto;
  max-height: 300px;
`

const RepoItem = styled(ListItem)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
  &:last-child {
    border-bottom: none;
  }
`

const RepoInfo = styled(FlexColumn)`
  width: 50%;
  flex: 1;
  gap: 0.25rem;
`

const RepoName = styled(Typography)`
  ${TextBold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const RepoDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.black.secondary};
  font-size: 0.875rem;
`

const LoadingContainer = styled(FlexColumn)`
  align-items: center;
  padding: 2rem;
  gap: 1rem;
`

const ItalicBold = styled.span`
  ${TextBold};
  font-style: italic;
`

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  orgId: string
  invitedEmail: string
  onClose?: () => void
}

export const OrganizationInviteRepoDialog = ({ isOpen, setOpen, orgId, invitedEmail, onClose }: Props) => {
  const [invitedRepos, setInvitedRepos] = useState<Set<string>>(new Set())
  const [repoModes, setRepoModes] = useState<Record<string, AccessMode>>({})
  const [loadingRepos, setLoadingRepos] = useState<Set<string>>(new Set())
  const { repos, loading: isLoadingRepos } = useOrganizationRepos(orgId)
  const onApiError = useContext(PublishApiErrorContext)
  const postAnalytics = useAnalytics()

  const handleInviteToRepo = async (repoId: string) => {
    await callAsync(
      async () => {
        await CollaboratorsService.srcHandlersv2InvitesCreateNew({
          repoId,
          requestBody: { mode: repoModes[repoId] || AccessMode.READ, collaborator_handle: invitedEmail },
        })
        setInvitedRepos((prev) => new Set([...prev, repoId]))
        postAnalytics('OrganizationInviteRepoDialogInvited', {
          org_id: orgId,
          repo_id: repoId,
          invitee_email: invitedEmail,
        })
      },
      (loading) =>
        setLoadingRepos((prev) =>
          loading ? new Set([...prev, repoId]) : new Set([...prev].filter((id) => id !== repoId))
        ),
      onApiError
    )
  }

  return (
    <ActionDialog
      title="Add to repositories"
      isOpen={isOpen}
      setOpen={setOpen}
      message={
        <FlexColumn gap={2}>
          <CollaborationImage />
          <Typography>
            Would you like to invite <ItalicBold>{invitedEmail}</ItalicBold> to any repositories?
          </Typography>
          {isLoadingRepos ? (
            <LoadingContainer>
              <CircularProgress size={24} />
              <Typography>Loading repositories...</Typography>
            </LoadingContainer>
          ) : (
            <StyledList>
              {repos?.map((repo) => (
                <RepoItem key={repo.repo_id}>
                  <FlexRow centered style={{ width: '100%' }}>
                    <RepoInfo>
                      <Tooltip title={repo.repo_name}>
                        <RepoName>{repo.repo_name}</RepoName>
                      </Tooltip>
                      {repo.description && <RepoDescription>{repo.description}</RepoDescription>}
                    </RepoInfo>
                    {invitedRepos.has(repo.repo_id) ? (
                      <Button variant="outlined" disabled>
                        Invited
                      </Button>
                    ) : (
                      <FlexRow centered>
                        <StyledAccessModeDropdownForInvite
                          disabled={loadingRepos.has(repo.repo_id)}
                          selectedMode={repoModes[repo.repo_id] || AccessMode.WRITE}
                          includeDelete={false}
                          onSelected={(mode) =>
                            setRepoModes((prev) => ({
                              ...prev,
                              [repo.repo_id]: mode as AccessMode,
                            }))
                          }
                        />
                        <Button
                          variant="contained"
                          onClick={() => handleInviteToRepo(repo.repo_id)}
                          disabled={loadingRepos.has(repo.repo_id)}
                          startIcon={loadingRepos.has(repo.repo_id) ? <CircularProgress size={16} /> : <Add />}
                        >
                          Invite
                        </Button>
                      </FlexRow>
                    )}
                  </FlexRow>
                </RepoItem>
              ))}
            </StyledList>
          )}
        </FlexColumn>
      }
      onConfirmAsync={async () => {
        onClose?.()
      }}
      confirmButtonLabel={'Done'}
      loadingMessage={'Done'}
      noSecondaryAction
    />
  )
}
