import React, { useCallback } from 'react'
import { ActionDialog } from '../dialogs/ActionDialog'
import { RepositoryManipulationService } from '../../api/coreapi'
import { BranchEx } from '../../models/BranchEx'
import { useRepo } from '../../hooks/api/useRepo'
import { CodeRef } from '../base/CodeRef'
type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  branch?: BranchEx
}

export const SetDefaultBranchDialog = ({ isOpen, setOpen, branch }: Props) => {
  const { refresh: refreshRepo } = useRepo(branch?.repoId!)
  const handleConfirmAsync = useCallback(async () => {
    if (!branch || !branch?.repoId || !branch.branch_id) return

    await RepositoryManipulationService.srcHandlersv2RepoSetDefaultBranch({
      repoId: branch.repoId,
      requestBody: {
        branch_id: branch.branch_id,
      },
    })
    refreshRepo()
  }, [refreshRepo, branch])

  return (
    <ActionDialog
      isOpen={isOpen}
      setOpen={setOpen}
      onConfirmAsync={handleConfirmAsync}
      title="Set default branch"
      message={
        <>
          Are you sure you want to make <CodeRef>{branch?.branch_name}</CodeRef> the default branch?
          <br />
          All new workspaces created for this repository will be based on <CodeRef>{branch?.branch_name}</CodeRef>.
        </>
      }
      confirmButtonLabel="Set default"
      loadingMessage="Setting default branch..."
    />
  )
}
