import { head } from 'lodash'
import { LOCALES } from './locales'
import { Commit } from '../api/coreapi'

const numberFormat = Intl.NumberFormat()

export const RIGHT_ARROW = '➞'
export const TWO_SIDED_ARROW = '⇆'
export const INTERPUNCT = '·'

export const EMPTY_COMMIT_MESSAGE_PLACEHOLDER = '-'

export const toInitials = (name: string) =>
  name
    .split(' ')
    .map((word) => word[0]?.toUpperCase() || '')
    .join('')

export const firstWord = (name: string) => head(name.split(' '))

const TRUNCATE_AT = 64

export const truncateCommitMessage = (commit: Commit) =>
  truncate((commit.commit_message || EMPTY_COMMIT_MESSAGE_PLACEHOLDER).trim(), 86)

export const getAuthorDisplayName = (commit: Commit) =>
  commit.author.full_name || commit.author.email || 'Unknown author'

export const truncate = (text: string, truncateAt: number = TRUNCATE_AT) => {
  return text.length > truncateAt ? text.substring(0, truncateAt) + '...' : text
}

export const truncateStart = (text: string) => {
  return text.length > TRUNCATE_AT ? '...' + text.substring(text.length - TRUNCATE_AT) : text
}

export const formatNumber = (value: number) => numberFormat.format(value)

export const pluralize = (value: number, word: string, suffix: string = 's', includeNumber: boolean = true) => {
  const prefix = includeNumber ? formatNumber(value) + ' ' : ''
  if (value === 1) {
    return prefix + word
  } else {
    return prefix + word + suffix
  }
}

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const formatBytes = (bytes: number) => {
  const i = bytes === 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, i)).toLocaleString(LOCALES, {
    maximumFractionDigits: 0,
    useGrouping: true,
  })} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`
}

export const stripPrefixOfCommitId = (commitId?: string) => commitId?.replace('dv.commit.', '')

export const joinWith = (separator: string, ...words: (string | undefined)[]) =>
  words.filter((word) => Boolean(word)).join(separator)

export const applyIfNotEmpty = (text: string | undefined, format: (s: string) => string) => (text ? format(text) : text)

export const withAngleBrackets = (text: string) => `<${text}>`
