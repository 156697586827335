import styled from '@emotion/styled'
import { TableCell } from '@mui/material'
import { TextRegular } from '../base/TextStyle'
import { Skeleton } from '@mui/material'
import { ReactNode } from 'react'

const StyledTableCell = styled(TableCell)`
  ${TextRegular};
  color: ${({ theme }) => theme.colors.black.primary};
  border: none;
`

export const TableCellWithLoading = ({ loading, value }: { loading: boolean; value?: string | ReactNode }) => (
  <StyledTableCell>{loading ? <Skeleton variant="text" width={210} /> : value}</StyledTableCell>
)
