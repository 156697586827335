import { createContext, ReactNode, useCallback, useState } from 'react'
import { log } from '../../utils/log'
import { BranchEx } from '../../models/BranchEx'
import { DeleteDialog } from './DeleteDialog'
import { RenameDialog } from './RenameDialog'
import { CheckoutDialog } from './CheckoutDialog'
import { ShelfChangesDialog } from './ShelfChangesDialog'
import { Shelf } from '../../api/coreapi'
import { ApplyShelfDialog } from './ApplyShelfDialog'
import { MergeDialog } from './MergeDialog'
import { MergeToDialog } from './MergeToDialog'
import { useNavigate } from 'react-router'
import { routeToWorkspaceEdit } from '../../Routes'
import { SetDefaultBranchDialog } from './SetDefaultBranchDialog'

type BranchActions = 'delete' | 'rename' | 'checkout' | 'merge' | 'merge-to' | 'set-default'
export type BranchActionExec = (action: BranchActions, branch: BranchEx) => void

type BranchActionContextProps = {
  doAction: BranchActionExec
}

export const BranchActionContext = createContext<BranchActionContextProps>({
  doAction: () => {},
})

export const useBranchActions = (): { doAction: BranchActionExec; dialogs: () => ReactNode } => {
  const [branch, setBranch] = useState<BranchEx>()
  const [shelves, setShelves] = useState<Shelf[]>([])
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)
  const [renameDialogIsOpen, setRenameDialogIsOpen] = useState(false)
  const [checkoutDialogIsOpen, setCheckoutDialogIsOpen] = useState(false)
  const [shelfChangesDialogIsOpen, setShelfChangesDialogIsOpen] = useState(false)
  const [applyShelfDialogIsOpen, setApplyShelfDialogIsOpen] = useState(false)
  const [mergeDialogIsOpen, setMergeDialogIsOpen] = useState(false)
  const [mergeToDialogIsOpen, setMergeToDialogIsOpen] = useState(false)
  const [defaultBranchDialogIsOpen, setDefaultBranchDialogIsOpen] = useState(false)
  const [workspaceIdForRedirection, setWorkspaceIdForRedirection] = useState<string>()
  const navigate = useNavigate()
  const doAction = useCallback((action: BranchActions, branch: BranchEx) => {
    setBranch(branch)
    switch (action) {
      case 'delete':
        setDeleteDialogIsOpen(true)
        break
      case 'checkout':
        setCheckoutDialogIsOpen(true)
        break
      case 'rename':
        setRenameDialogIsOpen(true)
        break
      case 'merge':
        setMergeDialogIsOpen(true)
        break
      case 'merge-to':
        setMergeToDialogIsOpen(true)
        break
      case 'set-default':
        setDefaultBranchDialogIsOpen(true)
        break
      default:
        log.error(`Unknown action: ${action}`, { branch })
        break
    }
  }, [])

  const renderDialogs = () => (
    <>
      <DeleteDialog isOpen={deleteDialogIsOpen} setOpen={setDeleteDialogIsOpen} branch={branch} />
      <RenameDialog isOpen={renameDialogIsOpen} setOpen={setRenameDialogIsOpen} branch={branch} />
      <CheckoutDialog
        isOpen={checkoutDialogIsOpen}
        setOpen={setCheckoutDialogIsOpen}
        branch={branch}
        onHasPendingChanges={(branchId: string, workspaceId?: string) => {
          workspaceId && setWorkspaceIdForRedirection(workspaceId)
          setShelfChangesDialogIsOpen(true)
        }}
        onHasShelvedChanges={(shelves, workspaceId) => {
          workspaceId && setWorkspaceIdForRedirection(workspaceId)
          setShelves(shelves)
          setApplyShelfDialogIsOpen(true)
        }}
      />
      <ShelfChangesDialog
        isOpen={shelfChangesDialogIsOpen}
        setOpen={setShelfChangesDialogIsOpen}
        branch={branch}
        onSuccess={() => {
          if (branch?.repoId && workspaceIdForRedirection) {
            navigate(routeToWorkspaceEdit(branch.repoId, workspaceIdForRedirection))
            setWorkspaceIdForRedirection(undefined)
          }
        }}
      />
      <ApplyShelfDialog
        isOpen={applyShelfDialogIsOpen}
        setOpen={setApplyShelfDialogIsOpen}
        branch={branch}
        shelves={shelves}
        onSuccess={() => {
          if (branch?.repoId && workspaceIdForRedirection) {
            navigate(routeToWorkspaceEdit(branch.repoId, workspaceIdForRedirection))
            setWorkspaceIdForRedirection(undefined)
          }
        }}
      />
      <MergeDialog isOpen={mergeDialogIsOpen} setOpen={setMergeDialogIsOpen} branch={branch} />
      <MergeToDialog isOpen={mergeToDialogIsOpen} setOpen={setMergeToDialogIsOpen} branch={branch} />
      <SetDefaultBranchDialog
        isOpen={defaultBranchDialogIsOpen}
        setOpen={setDefaultBranchDialogIsOpen}
        branch={branch}
      />
    </>
  )

  return {
    doAction,
    dialogs: renderDialogs,
  }
}
