import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { ActionDialog } from './ActionDialog'
import { OrganizationManagementService } from '../../api/coreapi'
import { infoToast } from '../../utils/toast'
import { FlexColumn, FlexRow } from '../base/Flex'
import { CodeRef } from '../base/CodeRef'
import { useOrganizationMemberRepos } from '../../hooks/api/useOrganizationMemberRepos'
import {
  CircularProgress,
  ListItemText,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import { Anchor } from '../base/Anchor'

type RemoveMemberDialogProps = {
  orgId: string
  orgName: string
  userId: string
  userName: string
  userEmail: string
  afterRemove: () => void
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const Bold = styled.div`
  font-weight: 500;
`

const ItalicBold = styled.span`
  font-style: italic;
  font-weight: 500;
`

const SupportLink = styled(Anchor)`
  color: ${({ theme }) => theme.colors.blue.primary};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.blue.dark};
  }
`

const StyledTable = styled(Table)`
  & th {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black.secondary};
  }
  & td,
  & th {
    padding: 8px;
  }
`

const ScrollableContainer = styled.div<{ hasScroll: boolean }>`
  max-height: 240px;
  overflow-y: auto;
  border: ${({ hasScroll, theme }) => (hasScroll ? `1px solid ${theme.colors.stroke}` : 'none')};
  border-radius: 4px;
`

const TruncatedCell = styled(TableCell)`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TruncatedListItemText = styled(ListItemText)`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const RemoveMemberDialog = ({
  orgId,
  orgName,
  userId,
  userName,
  userEmail,
  afterRemove,
  isOpen,
  setIsOpen,
}: RemoveMemberDialogProps) => {
  const postAnalytics = useAnalytics()
  const { repos, loading: isLoadingRepos } = useOrganizationMemberRepos(orgId, userId)
  const reposRef = useRef<HTMLDivElement>(null)
  const [hasScroll, setHasScroll] = useState(false)

  const isOwnerOfAnyRepo = repos?.some((repo) => repo.owner_user_id === userId)

  const userDisplayText = userName ? (
    <>
      <ItalicBold>{userName}</ItalicBold> ({userEmail})
    </>
  ) : (
    <ItalicBold>{userEmail}</ItalicBold>
  )

  useEffect(() => {
    if (isOpen) {
      postAnalytics('DeleteMemberDialogOpened', { org_id: orgId, user_id: userId })
    }
  }, [isOpen, postAnalytics, orgId, userId])

  // Check if content is scrollable
  useEffect(() => {
    if (!repos) return

    const checkScroll = () => {
      if (reposRef.current) {
        const { scrollHeight, clientHeight } = reposRef.current
        // Add a small buffer to prevent border flashing
        setHasScroll(scrollHeight > clientHeight + 2)
      }
    }

    // Initial check with a small delay to ensure content is rendered
    const timeoutId = setTimeout(checkScroll, 200)

    // Add resize observer
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(checkScroll, 50) // Small delay on resize
    })

    if (reposRef.current) {
      resizeObserver.observe(reposRef.current)
    }

    return () => {
      clearTimeout(timeoutId)
      resizeObserver.disconnect()
    }
  }, [repos])

  // Sort repos to show owned ones first
  const sortedRepos = [...(repos || [])].sort((a, b) => {
    if (a.owner_user_id === userId && b.owner_user_id !== userId) return -1
    if (a.owner_user_id !== userId && b.owner_user_id === userId) return 1
    return a.repo_name.localeCompare(b.repo_name)
  })

  return (
    <ActionDialog
      title="Remove member"
      isOpen={isOpen}
      setOpen={setIsOpen}
      onConfirmAsync={async () => {
        await OrganizationManagementService.srcHandlersv2OrganizationDeleteMember({
          orgId,
          userId,
        })
        infoToast(`${userName || userEmail} has been removed from ${orgName}.`, true)
        afterRemove()
      }}
      message={
        <FlexColumn gap={1}>
          {!isLoadingRepos && !isOwnerOfAnyRepo && (
            <div>
              Are you sure you want to remove {userDisplayText} from <ItalicBold>{orgName}</ItalicBold>?
            </div>
          )}
          {!isLoadingRepos && isOwnerOfAnyRepo && (
            <FlexColumn gap={1}>
              <div>Unable to remove {userDisplayText} as they are the owner of one or more repositories.</div>
              <div>
                Please <SupportLink target={'mailto:support@diversion.dev'}>contact support</SupportLink> for
                assistance.
              </div>
            </FlexColumn>
          )}
          {repos &&
            repos.length > 0 &&
            (isOwnerOfAnyRepo ? (
              <>
                <Bold>Repository access details:</Bold>
                <ScrollableContainer hasScroll={hasScroll} ref={reposRef}>
                  <StyledTable size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Repository</TableCell>
                        <TableCell>Access</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedRepos.map((repo) => (
                        <TableRow key={repo.repo_id}>
                          <TruncatedCell>
                            <Tooltip title={repo.repo_name} placement="top">
                              <CodeRef>{repo.repo_name}</CodeRef>
                            </Tooltip>
                          </TruncatedCell>
                          <TableCell>{repo.owner_user_id === userId ? 'Owner' : 'Member'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </ScrollableContainer>
              </>
            ) : (
              <>
                <Bold>This member will lose access to the following repositories:</Bold>
                <ScrollableContainer hasScroll={hasScroll} ref={reposRef}>
                  {repos.map((repo) => (
                    <ListItem key={repo.repo_id}>
                      <TruncatedListItemText>
                        <CodeRef key={repo.repo_id}>{repo.repo_name}</CodeRef>
                      </TruncatedListItemText>
                    </ListItem>
                  ))}
                </ScrollableContainer>
              </>
            ))}
          {isLoadingRepos && (
            <FlexRow centerContent>
              <CircularProgress />
            </FlexRow>
          )}
        </FlexColumn>
      }
      confirmButtonLabel="Remove member"
      loadingMessage="Removing member..."
      isDanger
      disabled={isLoadingRepos || isOwnerOfAnyRepo}
    />
  )
}
