import { ActionDialog } from './ActionDialog'
import React, { useState } from 'react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { Tier } from '../../api/coreapi'
import { sleep } from '../../utils/sleep'
import { IndieUserInfo, UserTierChooser } from '../user/UserTierChooser'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  onConfirm: (tier: Tier) => Promise<void>
}

export const IsSetTierButtonDisabled = (selectedTier: string, checkboxChecked: boolean, extraTierInfo?: string) => {
  if (selectedTier === Tier.PROFESSIONAL) {
    return false
  }
  if (!checkboxChecked) {
    return true
  }
  if (selectedTier === Tier.EDUCATION) {
    return !extraTierInfo?.trim()
  }
  return false
}

export const SetUserTierDialog = ({ isOpen, setOpen, onConfirm }: Props) => {
  const postAnalytics = useAnalytics()
  const [selectedTier, setSelectedTier] = useState(Tier.PROFESSIONAL)
  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const [contactMeChecked, setContactMeChecked] = useState(false)
  const [extraTierInfo, setExtraTierInfo] = useState('')
  const [indieTierInfo, setIndieTierInfo] = useState<IndieUserInfo>({})
  return (
    <ActionDialog
      title="Choose your organization type"
      isOpen={isOpen}
      onConfirmAsync={async () => {
        let extraProperties = {}
        if (selectedTier === Tier.INDIE) {
          extraProperties = {
            user_profession: indieTierInfo.userProfession,
            company_field: indieTierInfo.companyField,
            project_stage: indieTierInfo.projectStage,
            referred_by: indieTierInfo.referredBy,
            contact_me: contactMeChecked ? 'true' : 'false',
          }
        }
        postAnalytics('SetUserTierClicked', { tier: selectedTier, extra_tier_info: extraTierInfo, ...extraProperties })
        await Promise.all([onConfirm(selectedTier), sleep(2500)])
      }}
      message={
        <UserTierChooser
          selectedTier={selectedTier}
          setSelectedTier={setSelectedTier}
          checkboxChecked={checkboxChecked}
          setCheckboxChecked={setCheckboxChecked}
          contactMeChecked={contactMeChecked}
          setContactMeChecked={setContactMeChecked}
          setExtraInfo={setExtraTierInfo}
          indieTierInfo={indieTierInfo}
          setIndieUserTierInfo={setIndieTierInfo}
        />
      }
      setOpen={setOpen}
      confirmButtonLabel="Choose tier"
      loadingMessage="Choosing tier..."
      disabled={IsSetTierButtonDisabled(selectedTier, checkboxChecked, extraTierInfo)}
    />
  )
}
