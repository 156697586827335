import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Tier } from '../../api/coreapi'
import { FlexColumn } from '../base/Flex'
import { Checkbox } from '../base/Checkbox'

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
`
const InputFreetextField = styled(TextField)`
  width: 100%;
  text-align: left;
`

const MessageContainer = styled.div`
  margin-top: 10px;
  font-size: 12px;
`

const CheckboxContainer = styled.div`
  padding: 12px;
`

const TopAlignedFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;

  .MuiFormControlLabel-label {
    padding: 0 12px;
  }
`

const UserTierContainer = styled.div`
  color: ${({ theme }) => theme.colors.black.primary};
`

type UserTierChooserProps = {
  selectedTier: Tier
  setSelectedTier: (value: ((prevState: Tier) => Tier) | Tier) => void
  checkboxChecked: boolean
  setCheckboxChecked: (value: ((prevState: boolean) => boolean) | boolean) => void
  contactMeChecked: boolean
  setContactMeChecked: (value: ((prevState: boolean) => boolean) | boolean) => void
  setExtraInfo: (value: ((prevState: string) => string) | string) => void
  indieTierInfo: IndieUserInfo
  setIndieUserTierInfo: (value: ((prevState: IndieUserInfo) => IndieUserInfo) | IndieUserInfo) => void
}

export type IndieUserInfo = {
  userProfession?: string
  companyField?: string
  projectStage?: string
  referredBy?: string
}

type IndieInfoFormProps = {
  checkboxChecked: boolean
  setCheckboxChecked: (value: ((prevState: boolean) => boolean) | boolean) => void
  contactMeChecked: boolean
  setContactMeChecked: (value: ((prevState: boolean) => boolean) | boolean) => void
  indieTierInfo: IndieUserInfo
  setIndieUserTierInfo: (value: ((prevState: IndieUserInfo) => IndieUserInfo) | IndieUserInfo) => void
  setExtraInfo: (value: ((prevState: string) => string) | string) => void
}

export const IndieInfoForm = ({
  checkboxChecked,
  setCheckboxChecked,
  contactMeChecked,
  setContactMeChecked,
  indieTierInfo,
  setIndieUserTierInfo,
  setExtraInfo,
}: IndieInfoFormProps) => {
  return (
    <UserTierContainer>
      <FlexColumn gap={1} style={{ marginTop: '2rem' }}>
        <div>
          We're excited to offer you a generous FREE tier of our product! To help us serve you better, we'd greatly
          appreciate if you could answer a few quick questions. Your insights will directly shape our product
          improvements.
        </div>
        <FormControl>
          <InputLabel id="user-industry-label">I am a...</InputLabel>
          <Select
            aria-label="user-profession"
            name="user-profession"
            value={indieTierInfo.userProfession}
            label="I am a"
            sx={{
              width: '100%',
            }}
            onChange={(e) => setIndieUserTierInfo({ ...indieTierInfo, userProfession: e.target.value })}
          >
            <MenuItem value="Software developer">Software developer</MenuItem>
            <MenuItem value="Artist/Designer">Artist/Designer</MenuItem>
            <MenuItem value="Producer">Producer</MenuItem>
            <MenuItem value="IT expert">IT expert</MenuItem>
            <MenuItem value="Engineering manager">Engineering manager</MenuItem>
            <MenuItem value="Art director">Art director</MenuItem>
            <MenuItem value="Game designer">Game designer</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="user-industry-label">In my day job, the company I work for is in the field of...</InputLabel>
          <Select
            aria-label="user-industry"
            name="user-industry"
            value={indieTierInfo.companyField}
            label="My day job is in the field of"
            onChange={(e) => setIndieUserTierInfo({ ...indieTierInfo, companyField: e.target.value })}
          >
            <MenuItem value="Game development">Game development</MenuItem>
            <MenuItem value="Media and entertainment">Media and entertainment</MenuItem>
            <MenuItem value="Virtual production">Virtual production</MenuItem>
            <MenuItem value="Architecture / interior design">Architecture / interior design</MenuItem>
            <MenuItem value="Games infrastructure">Games infrastructure</MenuItem>
            <MenuItem value="This is my day job">I'm using Diversion for my day job</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="user-industry-label">My game is in the stage of...</InputLabel>
          <Select
            aria-label="game-stage"
            name="game-stage"
            value={indieTierInfo.projectStage}
            label="My game is in the stage of"
            sx={{
              width: '100%',
            }}
            onChange={(e) => setIndieUserTierInfo({ ...indieTierInfo, projectStage: e.target.value })}
          >
            <MenuItem value="Concept / pre-production">Just tinkering / concept / pre-production</MenuItem>
            <MenuItem value="Production">Production</MenuItem>
            <MenuItem value="Vertical slice / playable demo">Vertical slice / playable demo</MenuItem>
            <MenuItem value="Alpha / beta testing">Alpha / beta testing</MenuItem>
            <MenuItem value="Launch / post-launch">Launch / post-launch</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="user-industry-label">I found out about Diversion through...</InputLabel>
          <Select
            aria-label="referrer"
            name="referrer"
            value={indieTierInfo.referredBy}
            label="I found out about Diversion through"
            sx={{
              width: '100%',
            }}
            onChange={(e) => setIndieUserTierInfo({ ...indieTierInfo, referredBy: e.target.value })}
          >
            <MenuItem value="YouTube">YouTube</MenuItem>
            <MenuItem value="Conference">Conference (GDC / gamescom etc.)</MenuItem>
            <MenuItem value="Game jam">Game jam (GGJ, Brackeys etc.)</MenuItem>
            <MenuItem value="Reddit">Reddit</MenuItem>
            <MenuItem value="LinkedIn">LinkedIn</MenuItem>
            <MenuItem value="Discord">Discord</MenuItem>
            <MenuItem value="Colleague or friend">A colleague or a friend</MenuItem>
            <MenuItem value="Google search">Google search</MenuItem>
            <MenuItem value="AI assistant">AI assistant (ChatGPT, Gemini etc.)</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        {indieTierInfo.referredBy === 'Other' ||
        indieTierInfo.projectStage === 'Other' ||
        indieTierInfo.companyField === 'Other' ||
        indieTierInfo.userProfession === 'Other' ? (
          <InputFreetextField
            label="If marked Other for any question, please elaborate"
            variant="outlined"
            size="small"
            onChange={(e) => setExtraInfo(e.target.value)}
          />
        ) : (
          <></>
        )}
        <TierCheckbox
          checked={contactMeChecked}
          setChecked={setContactMeChecked}
          tooltip={''}
          label={"(optional) I'm happy to elaborate over a call / email"}
        />
        <TierCheckbox
          checked={checkboxChecked}
          setChecked={setCheckboxChecked}
          tooltip={'I am qualified for this tier'}
          label={
            'I certify my organization qualifies for the Indie tier: game development with under $100K revenue, less than $1M funding, and fewer than 20 people.'
          }
        />
      </FlexColumn>
    </UserTierContainer>
  )
}

export const UserTierChooser = ({
  selectedTier,
  setSelectedTier,
  checkboxChecked,
  setCheckboxChecked,
  contactMeChecked,
  setContactMeChecked,
  setExtraInfo,
  indieTierInfo,
  setIndieUserTierInfo,
}: UserTierChooserProps) => {
  useEffect(() => {
    setCheckboxChecked(false)
  }, [selectedTier, setCheckboxChecked])

  return (
    <UserTierContainer>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="user-tier"
          name="user-tier"
          value={selectedTier}
          onChange={(e) => setSelectedTier(e.target.value as Tier)}
        >
          <FormControlLabel
            label="Professional ($25/user, 21 day free trial, no credit card required)"
            control={<Radio />}
            value={Tier.PROFESSIONAL}
          />
          <FormControlLabel
            label="Indie game development (starting at $0/user)"
            control={<Radio />}
            value={Tier.INDIE}
          />
          <FormControlLabel label={'Educational'} control={<Radio />} value={Tier.EDUCATION} />
        </RadioGroup>
      </FormControl>
      <MessageContainer>
        Not sure which tier?{' '}
        <StyledLink href={`https://www.diversion.dev/pricing`} target="_blank" rel="noopener noreferrer">
          Compare tiers
        </StyledLink>
      </MessageContainer>
      {selectedTier === Tier.INDIE && (
        <IndieInfoForm
          indieTierInfo={indieTierInfo}
          setIndieUserTierInfo={setIndieUserTierInfo}
          checkboxChecked={checkboxChecked}
          setCheckboxChecked={setCheckboxChecked}
          contactMeChecked={contactMeChecked}
          setContactMeChecked={setContactMeChecked}
          setExtraInfo={setExtraInfo}
        />
      )}
      {selectedTier === Tier.EDUCATION && (
        <FlexColumn gap={1} centered>
          <TierCheckbox
            checked={checkboxChecked}
            setChecked={setCheckboxChecked}
            tooltip={' '}
            label={'I certify that my use of Diversion is for studies at a recognized academic institution.'}
          />
          <InputFreetextField
            required
            label="Institution and course name"
            variant="outlined"
            size="small"
            onChange={(e) => setExtraInfo(e.target.value)}
          />
        </FlexColumn>
      )}
    </UserTierContainer>
  )
}

const TierCheckbox = ({
  checked,
  setChecked,
  tooltip,
  label,
}: {
  checked: boolean
  setChecked: (checked: boolean) => void
  tooltip: string
  label: string
}) => (
  <FlexColumn>
    <CheckboxContainer>
      <TopAlignedFormControlLabel
        control={<Checkbox title={tooltip} checked={checked} setChecked={setChecked} />}
        label={label}
      />
    </CheckboxContainer>
  </FlexColumn>
)
