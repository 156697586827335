import React, { ReactNode } from 'react'
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  TableContainer,
} from '@mui/material'
import styled from '@emotion/styled'
import { TextRegular } from './TextStyle'

const StyledTable = styled(MuiTable)`
  width: 100%;
  table-layout: fixed;
`

const StyledPaper = styled(Paper)`
  overflow-y: auto;
  width: 100%;
`

const StyledTableCell = styled(TableCell)<{ colspan?: number }>`
  ${TextRegular};
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.black.secondary};
  padding: 16px;
`

export interface Column<T> {
  id: string
  label: string
  render: (row: T) => React.ReactNode
  width?: string
  align?: 'left' | 'right' | 'center'
}

interface TableProps<T> {
  columns: Column<T>[]
  data: T[]
  isLoading?: boolean
  page?: number
  rowsPerPage?: number
  totalCount?: number
  skeletonCell?: ReactNode
  onPageChange?: (newPage: number) => void
  onRowsPerPageChange?: (newRowsPerPage: number) => void
}

export function Table<T>({
  columns,
  data,
  isLoading = false,
  page = 0,
  rowsPerPage = 10,
  totalCount = 0,
  onPageChange,
  skeletonCell,
}: TableProps<T>) {
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPageChange?.(newPage)
  }

  const renderSkeletonRows = () => {
    return Array(rowsPerPage)
      .fill(0)
      .map((_, rowIndex) => <TableRow key={`skeleton-${rowIndex}`}>{skeletonCell}</TableRow>)
  }

  return (
    <StyledPaper>
      <TableContainer>
        <StyledTable stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell key={column.id} style={{ width: column.width }} align={column.align}>
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              renderSkeletonRows()
            ) : data.length === 0 ? (
              <TableRow>
                <StyledTableCell colSpan={columns.length} align="center">
                  No data available
                </StyledTableCell>
              </TableRow>
            ) : (
              data.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <StyledTableCell key={column.id} align={column.align}>
                      {column.render(row)}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </StyledTable>
        {!isLoading && data.length > 0 && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              showFirstButton
              showLastButton
            />
          </Box>
        )}
      </TableContainer>
    </StyledPaper>
  )
}
