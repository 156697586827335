import { css, Theme, Theme as EmotionTheme } from '@emotion/react'
import { createTheme } from '@mui/material'
import { TextRegular } from './components/base/TextStyle'
import { changeColorOpacity } from './utils/colorUtils'

declare module '@emotion/react' {
  export interface Theme {
    colors: {
      header: {
        background: string
        primaryText: string
        border: string
      }
      overlay: {
        primary: string
      }
      card: {
        primary: string
      }
      authCard: {
        primary: string
      }
      blue: {
        primary: string
        dark: string
        medium: string
        light: string
        hover: string
        contrastText: string
      }
      white: {
        primary: string
        secondary: string
      }
      black: {
        primary: string
        secondary: string
      }
      red: {
        primary: string
        dark: string
        contrastText: string
      }
      syncStatus: {
        error: string
        paused: string
      }
      stroke: string
      background: string
      warning: {
        primary: string
        contrastText: string
      }
      status: {
        added: string
        modified: string
        deleted: string
        moved: string
      }
    }
    padding: {
      s: number
      m: number
      l: number
      xl: number
    }
  }
}

declare module '@mui/system/createTheme/createTheme' {
  interface Theme extends EmotionTheme {}

  interface ThemeOptions extends Theme {}
}

export const lightTheme: Theme = {
  colors: {
    header: {
      background: '#191919',
      primaryText: '#FFFFFF',
      border: '#FFFFFF',
    },
    overlay: {
      primary: 'rgba(0,0,0,0.75)',
    },
    card: {
      primary: '#F5F5F7',
    },
    authCard: {
      primary: '#F5F5F7',
    },
    blue: {
      primary: '#477BFF',
      dark: '#1B41A4',
      medium: '#6DADED',
      light: '#F0F6FA',
      hover: 'rgba(224, 230, 249, 1)',
      contrastText: '#FFFFFF',
    },
    white: {
      primary: '#FFFFFF',
      secondary: '#E5E5E5',
    },
    black: {
      primary: '#191919',
      secondary: '#737373',
    },
    red: {
      primary: '#D24D4D',
      dark: '#ab2b2b',
      contrastText: '#FFFFFF',
    },
    syncStatus: {
      error: '#ab2b2b',
      paused: '#E9903F',
    },
    stroke: '#D4D4D4',
    background: '#f9f9f9',
    warning: {
      primary: '#8C4ED2',
      contrastText: '#FFFFFF',
    },
    status: {
      added: '#1B41A4',
      modified: '#E9903F',
      deleted: '#D24D4D',
      moved: '#4DD272',
    },
  },
  padding: {
    s: 0.2,
    m: 0.5,
    l: 1,
    xl: 2,
  },
}

export const darkTheme: Theme = {
  colors: {
    header: {
      background: '#191919',
      primaryText: '#FFFFFF',
      border: '#444444',
    },
    syncStatus: {
      error: '#ab2b2b',
      paused: '#f3b069',
    },
    overlay: {
      primary: 'rgba(0,0,0,0.75)',
    },
    card: {
      primary: '#1d2021',
    },
    authCard: {
      primary: '#F5F5F7',
    },
    blue: {
      primary: '#508ec8',
      dark: '#2572ba',
      medium: '#2A2D3D',
      light: '#3a3c3c',
      hover: 'rgb(66,69,76)',
      contrastText: '#FFFFFF',
    },
    black: {
      primary: '#e6e6e6',
      secondary: '#B5B5B5',
    },
    white: {
      primary: '#181a1b',
      secondary: '#757474',
    },
    red: {
      primary: '#ed7979',
      dark: '#e65252',
      contrastText: '#FFFFFF',
    },
    stroke: '#444444',
    background: '#1b1e1f',
    warning: {
      primary: '#9768cc',
      contrastText: '#FFFFFF',
    },
    status: {
      added: '#92aaf8',
      modified: '#f3b069',
      deleted: '#ed7979',
      moved: '#83ec9f',
    },
  },
  padding: {
    s: 0.2,
    m: 0.5,
    l: 1,
    xl: 2,
  },
}

const Z_INDEX_POPOVER = 1301 // cause ant use 1300
export const Z_INDEX_TOPMOST = 1302

export const GlobalStyle = (theme: Theme) => {
  const white40percent = changeColorOpacity(theme.colors.white.primary, 0.4)
  return css`
    body {
      overflow: hidden;
      color: ${theme.colors.white.primary};
      cursor: default;
      ${TextRegular};
    }

    .MuiPaper-root {
      max-width: unset !important;
    }

    .MuiTooltip-arrow {
      color: ${theme.colors.blue.primary} !important;
    }

    .MuiTooltip-tooltip {
      z-index: ${Z_INDEX_POPOVER};
      background-color: ${theme.colors.blue.primary} !important;
      font-size: 0.8rem !important;
    }

    .ant-dropdown {
      z-index: ${Z_INDEX_POPOVER};
      min-width: unset !important;
      width: fit-content;
    }

    .ant-dropdown-menu {
      background-color: ${theme.colors.blue.primary} !important;
      width: max-content;
    }

    .ant-dropdown-arrow::before {
      background: ${theme.colors.blue.primary} !important;
    }

    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
      padding: 0;
    }

    .ant-dropdown-menu-item {
      color: ${theme.colors.blue.contrastText} !important;

      :hover {
        color: ${theme.colors.black.primary} !important;
        background-color: ${theme.colors.white.secondary} !important;
      }
    }

    .ant-dropdown-menu-item-disabled {
      background-color: ${white40percent} !important;

      :hover {
        cursor: default !important;
        color: ${theme.colors.white.primary} !important;
        background-color: ${white40percent} !important;
      }
    }

    .ant-dropdown-menu-item-divider {
      background-color: ${white40percent} !important;
    }

    .ant-popover-inner {
      background-color: ${theme.colors.blue.primary} !important;
    }

    .ant-popover-arrow::before {
      background: ${theme.colors.blue.primary} !important;
    }

    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0.85) !important;
    }
  `
}

export const HEADER_HEIGHT_REM = 4

export const SIDE_PANEL_WIDTH_REM = 28
export const SIDE_PANEL_MIN_WIDTH_REM = 17
export const SIDE_PANEL_MAX_WIDTH_REM = 43

export const TREE_LEFT_MARGIN_REM = 0.5
export const TREE_LEFT_MARGIN_REM_PADDED = TREE_LEFT_MARGIN_REM + 0.2

export type Styleable = {
  className?: string
}

export const muiLightTheme = {
  // @ts-ignore
  ...createTheme(lightTheme),
  shadows: Array(25).fill('none'),
}
export const muiDarkTheme = {
  // @ts-ignore
  ...createTheme({
    ...darkTheme,
    palette: {
      mode: 'dark',
    },
  }),
  shadows: Array(25).fill('none'),
}

export const remToPx = (rem: number) => rem * parseInt(getComputedStyle(document.documentElement).fontSize)
