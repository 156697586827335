import { useItemListApi } from '../useApi'
import { OrganizationManagementService, Repo } from '../../api/coreapi'
import { useMemo } from 'react'
import { sortBy } from 'lodash'

export const useOrganizationRepos = (orgId?: string) => {
  const {
    data: repos,
    loading,
    refresh,
  } = useItemListApi<Repo>(
    ['organizations', orgId, 'repos'],
    orgId ? () => OrganizationManagementService.srcHandlersv2OrganizationListRepos({ orgId }) : null
  )
  const sortedRepos = useMemo(() => (repos ? sortBy(repos, (repo) => repo.repo_name.toLowerCase()) : repos), [repos])
  return { repos: sortedRepos, loading, refresh }
}
