import { useCallback, useEffect, useMemo, useState } from 'react'
import { Organization, Collaborator } from '../../api/coreapi'
import { useOrganizationRepos } from './useOrganizationRepos'
import { CollaboratorsService } from '../../api/coreapi'
import { callAsync } from '../../utils/callAsync'
import { errorToast } from '../../utils/toast'

export const useOrganizationReposWithCollaborators = (
  currentOrg?: Organization,
  currentPage: number = 0,
  isOrgAdmin: boolean = false
) => {
  const { repos, loading: isReposLoading, refresh: refreshRepos } = useOrganizationRepos(currentOrg?.id)
  const [collaborators, setCollaborators] = useState<Record<string, Array<Collaborator>>>({})
  const [isLoadingCollaborators, setIsLoadingCollaborators] = useState(false)

  const pageSize = 10

  const paginatedRepos = useMemo(() => {
    if (!repos) return []
    const start = currentPage * pageSize
    const end = start + pageSize
    return repos.slice(start, end)
  }, [repos, currentPage])

  const onError = useCallback((error: Error) => {
    errorToast('Failed to fetch collaborators')
    setIsLoadingCollaborators(false)
  }, [])

  // Fetch collaborators only when repos are loaded
  // and only for the repos displayed on the current page.
  useEffect(() => {
    if (isReposLoading || !paginatedRepos.length || !isOrgAdmin) return
    callAsync(
      async () => {
        const collaboratorsMap: Record<string, Array<Collaborator>> = {}
        await Promise.all(
          paginatedRepos.map(async (repo) => {
            const response = await CollaboratorsService.srcHandlersCollaborationListCollaborators({
              repoId: repo.repo_id,
            })
            collaboratorsMap[repo.repo_id] = response.collaborators ?? []
          })
        )
        setCollaborators(collaboratorsMap)
      },
      setIsLoadingCollaborators,
      onError
    )
  }, [paginatedRepos, isReposLoading, setCollaborators, onError, isOrgAdmin])

  const reposWithCollaborators = useMemo(
    () =>
      paginatedRepos?.map((repo) => ({
        ...repo,
        collaborators: collaborators[repo.repo_id] ?? [],
      })),
    [paginatedRepos, collaborators]
  )

  return {
    repos: reposWithCollaborators,
    loading: isReposLoading || isLoadingCollaborators,
    refresh: refreshRepos,
    total: repos?.length ?? 0,
  }
}
