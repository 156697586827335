/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error';
import type { FileEntry } from '../models/FileEntry';
import type { FileHistoryEntry } from '../models/FileHistoryEntry';
import type { GitRepoImport } from '../models/GitRepoImport';
import type { Repo } from '../models/Repo';
import type { SetDefaultBranchRequest } from '../models/SetDefaultBranchRequest';
import type { StorageBackend } from '../models/StorageBackend';
import type { StorageUri } from '../models/StorageUri';
import type { WorkspaceJournalOrdinalId } from '../models/WorkspaceJournalOrdinalId';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RepositoryManipulationService {
    /**
     * Get details of an existing repo.
     * Get details of an existing repo.
     * @returns Repo Repo object with the requested identifier
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2RepoGet({
        repoId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
    }): CancelablePromise<Repo | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}',
            path: {
                'repo_id': repoId,
            },
        });
    }
    /**
     * Delete a repo.
     * Delete a repo. All files, workspaces, commits, branches permissions etc. associated with the repo will be deleted.
     * @returns Error An error occurred
     * @returns any Repo deletion started asynchronously.
     * @throws ApiError
     */
    public static srcHandlersv2RepoDelete({
        repoId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
    }): CancelablePromise<Error | any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/repos/{repo_id}',
            path: {
                'repo_id': repoId,
            },
        });
    }
    /**
     * Import a Git repo into this Diversion repo
     * Import a Git repo with all history from a Git URL. {repo_id} must have no commits and no branches, or be already marked as synced with the same git_url. The git repo URL must have http credentials embedded or otherwise hosted on Github and the owner has the Diversion Github app integration set up.
     * @returns Error An error occurred
     * @returns any Import started asynchronously. Poll the repo until it shows a default branch.
     * @throws ApiError
     */
    public static srcHandlersIntegrationsGitImportImportInBackground({
        repoId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        requestBody?: GitRepoImport,
    }): CancelablePromise<Error | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/import',
            path: {
                'repo_id': repoId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Repo is not empty or is already synced with another URL.`,
                422: `Repo credentials are not set and therefore it cannot be imported.`,
            },
        });
    }
    /**
     * Check if a git repo could be imported to Diversion
     * The git repo URL must have http credentials embedded or otherwise hosted on Github and the owner has the Diversion Github app integration set up.
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersIntegrationsGitImportImportVerifyUrl({
        requestBody,
    }: {
        requestBody?: GitRepoImport,
    }): CancelablePromise<Error> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/import/check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Repo credentials are not set and therefore it cannot be imported.`,
            },
        });
    }
    /**
     * Sync a Git repo to this Diversion repo
     * Sync all history from Git. The repo must already be associated with a Git repo (see import).
     * @returns Error An error occurred
     * @returns any Sync started asynchronously.
     * @throws ApiError
     */
    public static srcHandlersIntegrationsGithubHandleRepoGithubWebhook({
        repoId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
    }): CancelablePromise<Error | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/sync',
            path: {
                'repo_id': repoId,
            },
        });
    }
    /**
     * Change default branch for a repository.
     * @returns any Request completed successfully
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2RepoSetDefaultBranch({
        repoId,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        requestBody: SetDefaultBranchRequest,
    }): CancelablePromise<any | Error> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/default_branch',
            path: {
                'repo_id': repoId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Get file tree snapshot. Either one of workspace, branch or commit ID needs to be specified as ref ID. (Executed on secondary shard)
     * @returns any Success
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2TreeFiletree({
        repoId,
        refId,
        path,
        recurse = true,
        skip,
        limit = 1500,
        offset,
        includeDeleted = false,
        workspaceJournalOrdinalId,
        itemNameQuery,
        dirsOnly = false,
        useSelectiveSync = true,
        maxDepth = 1,
        ignoreJournalOrdinalDiff = false,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a workspace, branch or commit.
         */
        refId: string,
        /**
         * Optional path to an item inside the repository.
         */
        path?: string,
        /**
         * Specifies if to recursively iterate file tree to next directory levels
         */
        recurse?: boolean,
        /**
         * Skip a number of items returned from a listing api
         */
        skip?: number,
        /**
         * Limit the number of entries returned from walk
         */
        limit?: number,
        /**
         * Offset item name in directory when iterating its entries (non-inclusive)
         */
        offset?: string,
        /**
         * Should the tree contain also deleted items or omit them from response
         */
        includeDeleted?: boolean,
        /**
         * The ordinal ID of the journal entry the walk references, if applicable
         */
        workspaceJournalOrdinalId?: WorkspaceJournalOrdinalId,
        /**
         * A query string to test against item name, only matching items will be collected
         */
        itemNameQuery?: string,
        /**
         * Return only directories in the file tree walk
         */
        dirsOnly?: boolean,
        /**
         * Return the results filtered by the workspace preferences for selective sync
         */
        useSelectiveSync?: boolean,
        maxDepth?: number,
        /**
         * Specifies if to ignore the journal ordinal id difference and potentially return intermediate state
         */
        ignoreJournalOrdinalDiff?: boolean,
    }): CancelablePromise<{
        object: 'FileEntry';
        items: Array<FileEntry>;
        workspace_journal_ordinal_id?: WorkspaceJournalOrdinalId;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/trees/{ref_id}',
            path: {
                'repo_id': repoId,
                'ref_id': refId,
            },
            query: {
                'path': path,
                'recurse': recurse,
                'skip': skip,
                'limit': limit,
                'offset': offset,
                'include_deleted': includeDeleted,
                'workspace_journal_ordinal_id': workspaceJournalOrdinalId,
                'item_name_query': itemNameQuery,
                'dirs_only': dirsOnly,
                'use_selective_sync': useSelectiveSync,
                'max_depth': maxDepth,
                'ignore_journal_ordinal_diff': ignoreJournalOrdinalDiff,
            },
            errors: {
                400: `The request does not meet the required conditions`,
                404: `The specified resource was not found`,
                412: `The specified resource is no longer up to date`,
            },
        });
    }
    /**
     * Get object history in a ref by its latest path
     * @returns any List of changes in the history of a versioned file
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2CommitGetObjectHistory({
        repoId,
        refId,
        path,
        limit = 100,
        skip,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a workspace, branch or commit.
         */
        refId: string,
        /**
         * A path to an item inside the repository.
         */
        path: string,
        /**
         * Limit the number or items returned from a listing api
         */
        limit?: number,
        /**
         * Skip a number of items returned from a listing api
         */
        skip?: number,
    }): CancelablePromise<{
        entries: Array<FileHistoryEntry>;
    } | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/files/history/{ref_id}/{path}',
            path: {
                'repo_id': repoId,
                'ref_id': refId,
                'path': path,
            },
            query: {
                'limit': limit,
                'skip': skip,
            },
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Get file entry (either tree or blob). Either one of workspace, branch or commit ID needs to be specified.
     * @returns FileEntry File information
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2FilesGetFileEntry({
        repoId,
        refId,
        path,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a workspace, branch or commit.
         */
        refId: string,
        /**
         * A path to an item inside the repository.
         */
        path: string,
    }): CancelablePromise<FileEntry | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/files/{ref_id}/{path}',
            path: {
                'repo_id': repoId,
                'ref_id': refId,
                'path': path,
            },
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Get blob contents snapshot. Either one of workspace, branch or commit ID needs to be specified.
     * @returns binary File contents
     * @throws ApiError
     */
    public static srcHandlersv2FilesGetBlob({
        repoId,
        refId,
        path,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a workspace, branch or commit.
         */
        refId: string,
        /**
         * A path to an item inside the repository.
         */
        path: string,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/blobs/{ref_id}/{path}',
            path: {
                'repo_id': repoId,
                'ref_id': refId,
                'path': path,
            },
            responseType: 'blob',
        });
    }
    /**
     * Get blob contents using storage information, as retrieved from other APIs.
     * @returns binary File contents
     * @throws ApiError
     */
    public static srcHandlersv2FilesDownloadBlob({
        repoId,
        storageBackend,
        storageUri,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * Storage type of the blob.
         */
        storageBackend: StorageBackend,
        /**
         * Storage uri of the blob.
         */
        storageUri: StorageUri,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/blobs/download',
            path: {
                'repo_id': repoId,
            },
            query: {
                'storage_backend': storageBackend,
                'storage_uri': storageUri,
            },
            responseType: 'blob',
        });
    }
}
