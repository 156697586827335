import { useAgentApi } from '../../hooks/useAgentApi'
import { ApiError, DefaultService } from '../../api/agentapi'
import { milliseconds } from 'date-fns'
import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { SyncStatusType } from '../../components/header/syncstatus/SyncStatusContext'
import { isNetworkError } from '../../utils/errorClassify'
import { StatusCodes } from 'http-status-codes'

const WorkspaceSyncStatusInterval: Duration = { seconds: 2 }

export const useSyncProgress = ({
  repoId,
  workspaceId,
  refreshInterval = WorkspaceSyncStatusInterval,
}: {
  repoId: string
  workspaceId: string
  refreshInterval?: Duration
}) => {
  const { data: workspaceStatusRes } = useAgentApi(
    `fetch-workspace-status-${workspaceId}`,
    async () => {
      try {
        const data = await DefaultService.getSyncProgress({
          repoId: repoId,
          workspaceId: workspaceId,
        })
        return { data }
      } catch (error: any) {
        return { error }
      }
    },
    false,
    { refreshInterval: milliseconds(refreshInterval) }
  )
  const { syncStatus, syncingInfo, remainingActions, blobTransferStatus } = useMemo(() => {
    if (workspaceStatusRes?.data?.IsPaused) {
      const syncingInfo = workspaceStatusRes?.data?.LastErr && `Paused - ${workspaceStatusRes?.data?.LastErr}`
      return { syncStatus: SyncStatusType.Paused, syncingInfo }
    }
    if (workspaceStatusRes?.data?.LastErr) {
      return { syncStatus: SyncStatusType.Error, syncingInfo: workspaceStatusRes?.data?.LastErr }
    }
    if (!!workspaceStatusRes?.error) {
      const error = workspaceStatusRes.error
      if (isNetworkError(error)) {
        return { syncStatus: SyncStatusType.AgentDownError, syncingInfo: 'local agent is down' }
      } else if (error instanceof ApiError && error.status === StatusCodes.NOT_FOUND) {
        // We have a connection to the agent, but the agent doesn't know about this workspace (e.x not cloned workspace)
        return { syncStatus: SyncStatusType.None }
      }
    }
    const syncProgress = workspaceStatusRes?.data
    const statusUnknownItemCount = -1
    if (
      syncProgress?.FileStats?.Inbound?.ItemsCount === statusUnknownItemCount ||
      syncProgress?.FileStats?.Outbound?.ItemsCount === statusUnknownItemCount
    ) {
      return { syncStatus: SyncStatusType.Syncing }
    }
    if (isEmpty(syncProgress?.CurrentSyncAction)) {
      return { syncStatus: SyncStatusType.Synced }
    }
    const remainingActions =
      (syncProgress?.FileStats?.Outbound?.ItemsCount || 0) + (syncProgress?.FileStats?.Inbound?.ItemsCount || 0)
    const infoSuffix = remainingActions > 1 ? ` and ${remainingActions - 1} more` : ''
    const blobTransferStatus = syncProgress?.BlobTransferStatus || ''
    return {
      syncStatus: SyncStatusType.Syncing,
      syncingInfo: `${syncProgress?.CurrentSyncAction}${infoSuffix}`,
      remainingActions,
      blobTransferStatus,
    }
  }, [workspaceStatusRes])

  return { syncStatus, syncingInfo, remainingActions, blobTransferStatus }
}
